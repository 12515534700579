  import React, { useState } from 'react';
  import { useParams } from 'react-router-dom';
  import { useQuery } from '@tanstack/react-query';
  // import { Header } from './../components/header/header';
  // import { Topbar } from './../components/topbar/topbar';
  // import { CartSidebar } from './../components/cartsidebar/cartsidebar';
  import { Productslist } from '../components/productslist/productslist';
  import { getProductsbysubcate } from "./../services/api";

  export const Products = () => {
    const { id } = useParams();
    const { data, error, isLoading } = useQuery({
      queryKey: ['products', id],
      queryFn: () => getProductsbysubcate(id),
      cacheTime: 10 * 60 * 1000, // 10 minutes
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000, // 1 minute
    });

    const [isCartOpen, setIsCartOpen] = useState(false);
    const toggleCart = () => {
      setIsCartOpen(!isCartOpen);
    };

    return (
      <>
        {/* <Topbar />
        <Header toggleCart={toggleCart} isCartOpen={isCartOpen} />
        <CartSidebar isOpen={isCartOpen} toggleCart={toggleCart}/> */}
        <main>
          {isLoading ? (
            <h3>Loading...</h3> // Display loading message while fetching data
          ) : error ? (
            <h3>Error fetching products</h3> // Display error message if the API call fails
          ) : data && data.data.length > 0 ? (
            <Productslist data={data.data}/>
          ) : (
            <h3>No Products Found</h3> // Display message if no products are found
          )}
        </main>
      </>
    );
  };
