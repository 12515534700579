// components/LoadingOverlay.js
import React from 'react';
import './loadingoverlay.scss'; // We'll define the styles here

export const LoadingOverlay = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-content">
        {/* You can replace this with a GIF image */}
        <img src="/img/loding.gif" alt="Loading..." />
      </div>
    </div>
  );
};
