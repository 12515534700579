import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {Header} from './components/header/header';
import {Topbar} from './components/topbar/topbar';
import { CartSidebar } from './components/cartsidebar/cartsidebar';
import {Footer} from './components/footer/footer';
import {Home} from './pages/Home';
import {About} from './pages/About';
import {Privacy} from './pages/Privacy';
import {Terms} from './pages/Terms';
import {Shipping} from './pages/Shipping';
import {Products} from './pages/products';
import {Register} from './pages/register';
import {Login} from './pages/login';
import {Account} from './pages/account';
import {Search} from './pages/search';
import "./styles/main.scss";

const App = () => {
    const [isCartOpen, setIsCartOpen] = useState(false);

    const toggleCart = () => {
      setIsCartOpen(!isCartOpen);
    };
    return (
        <Router>
            <div className="app">
                <div className="postion_sticky">
                <Topbar/>
                <Header toggleCart={toggleCart} isCartOpen={isCartOpen}/>
                <CartSidebar isOpen={isCartOpen} toggleCart={toggleCart} />
                </div>
                <main>
                          <Routes>
                              <Route path="/" element={<Home />} />
                              <Route path="/about" element={<About />} />
                              <Route path="/privacy" element={<Privacy />} />
                              <Route path="/terms" element={<Terms />} />
                              <Route path="/shipping" element={<Shipping />} />
                              <Route path="/products" element={<Products />} />
                              <Route path="/products/:id" element={<Products />} />
                              <Route path="/register" element={<Register />} />
                              <Route path="/login" element={<Login />} />
                              <Route path="/account" element={<Account />} />
                              <Route path="/search" element={<Search />} />
                          </Routes>
                      </main>
                <Footer />
            </div>
        </Router>
    );
};

export default App;
