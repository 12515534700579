import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {Categorybox} from "./../components/categorybox/categorybox";
// import {Header} from './../components/header/header';
import { Link } from 'react-router-dom';
// import {Topbar} from './../components/topbar/topbar';
// import { CartSidebar } from './../components/cartsidebar/cartsidebar';
import {Newarrivals} from "./../components/newarrivals/newarrivals";
import {Bestseller} from "./../components/bestseller/bestseller";
import {Footer} from "./../components/footer/footer";
import {getSubCategories} from "./../services/api";

export const Home = () => {
    const { data: data, error, isLoading } = useQuery({
        queryKey: ['subcategories'],
        queryFn: getSubCategories,
        cacheTime: 10 * 60 * 1000, // 10 minutes
        refetchOnWindowFocus: false,
        staleTime: 60 * 1000, // 1 minute
    });
    
    const [isCartOpen, setIsCartOpen] = useState(false);

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };
    return (<>
        {/* <Topbar/>
        <Header toggleCart={toggleCart} isCartOpen={isCartOpen}/>
        <CartSidebar isOpen={isCartOpen} toggleCart={toggleCart} /> */}
        <main>
            <div className="category_wrapper">
                <div className="container">
                    <div className="categorylist">
                        {data?.data.map((category, index) => (
                            <Link to={`/products/${category._id}`} key={index}>
                            <Categorybox  title={category.name} img={category.image ? `https://api.svzler.com/${category.image}` : "/img/c_pen.png"} />
                            </Link>
                        ))}
                        {/* <Categorybox    ={"Pen & Pencile"} img={"/img/c_pen.png"}/>
                        <Categorybox    ={"Notebook & Drawingbook"} img={"/img/c_pen.png"}/>
                        <Categorybox    ={"Color"} img={"/img/c_pen.png"}/>
                        <Categorybox    ={"Sharpener & Eraser"} img={"/img/c_pen.png"}/>
                        <Categorybox    ={"Student kit"} img={"/img/c_pen.png"}/>
                        <Categorybox    ={"Glue & tap"} img={"/img/c_pen.png"}/>
                        <Categorybox    ={"Geometry Sets"} img={"/img/c_pen.png"}/>
                        <Categorybox    ={"Pad & Slate"} img={"/img/c_pen.png"}/>
                        <Categorybox    ={"Chalks"} img={"/img/c_pen.png"}/>
                        <Categorybox    ={"Dairy & Billbook"} img={"/img/c_pen.png"}/>
                        <Categorybox    ={"Compass & pouch"} img={"/img/c_pen.png"}/>
                        <Categorybox    ={"Marker & Highlighter"} img={"/img/c_pen.png"}/>
                        <Categorybox    ={"Keychains"} img={"/img/c_pen.png"}/>
                        <Categorybox    ={"Toy"} img={"/img/c_pen.png"}/>
                        <Categorybox    ={"Art & Craft"} img={"/img/c_pen.png"}/>
                        <Categorybox    ={"Xerox paper"} img={"/img/c_pen.png"}/>
                        <Categorybox    ={"Staplers & Pins"} img={"/img/c_pen.png"}/>
                        <Categorybox    ={"Files & Folder"} img={"/img/c_pen.png"}/>
                        <Categorybox    ={"Gift"} img={"/img/c_pen.png"}/>
                        <Categorybox    ={"Other"} img={"/img/c_pen.png"}/> */}
                    </div>
                </div>
            </div>
            {/* <Newarrivals/> */}
            {/* <Newarrivals/>
            <Bestseller/> */}
        </main></>
    );
};