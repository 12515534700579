import React from "react";

export const Shipping = () => {
    return (
        <main>
            <div className="privacy_main_content container p_date">
            Last Updated: 07/09/2024<br/><br/>
            </div>
            <div className="privacy_main_content container">
            Thank you for choosing svzler. We strive to ensure that your orders are delivered in a timely and efficient manner. This Shipping Policy outlines the terms and conditions governing the shipping and delivery of products ordered through our platform.
            </div>
            <div className="privacy_title">1. Order Processing</div>
            <div className="privacy_main_content container">
            Order Confirmation: Once an order is placed, you will receive an email confirmation with the order details. Please ensure that all provided shipping information is accurate.
            </div>
            <div className="privacy_main_content container">
            Processing Time: Orders are processed within 24 hours of order confirmation. Processing times may vary depending on the product, availability, and time of order.
            <br/>
            Order Cut-Off Time: Orders placed after 12:30pm or on weekends and holidays will be processed the next business day.
            </div>
            <div className="privacy_title">2. Shipping Costs</div>
            <div className="privacy_main_content container">
            Shipping costs are calculated at checkout based on the weight, size, and destination of the package.
            <br/>
            Any applicable shipping fees, taxes, or duties will be displayed before finalizing your order.
            <br/>
            Free shipping may be available for certain products or promotions as specified on the platform.
            </div>
            <div className="privacy_title">3. Delivery Locations
            </div>
            <div className="privacy_main_content container">
            We deliver to most locations within surat. However, there may be restrictions on certain remote areas. If you are in a restricted location, we will notify you during checkout or contact you directly to arrange alternative options.
            </div>
            <div className="privacy_title">4. Failed Deliveries or Re-Shipments
            </div>
            <div className="privacy_main_content container">
            Incorrect Address: It is your responsibility to ensure that the shipping address provided is correct. If the address is incorrect or incomplete, we cannot be held responsible for failed deliveries.
                </div>
            <div className="privacy_main_content container">
            Failed Delivery Attempts: If our delivery partner is unable to deliver the package after multiple attempts, the order will be returned to the sender. Re-shipment may incur additional charges.
            </div>
            <div className="privacy_main_content container">
            Unclaimed Packages: If a package is unclaimed for a specified period, we reserve the right to dispose of the package without further notice.
            </div>
            <div className="privacy_title">5. Shipping Delays
            </div>
            <div className="privacy_main_content container">
            While we strive to meet delivery timelines, some factors may cause delays, including:
            <br/>
            Weather conditions.
            <br/>
            Carrier delays or service disruptions.
            <br/>
            Natural disasters or other unforeseen events.
            <br/>
            In the event of a significant delay, we will notify you via email and work to resolve the issue as soon as possible.
            </div>
            <div className="privacy_title">6. Damaged or Lost Packages
            </div>
            <div className="privacy_main_content container">
            Damaged Packages: If your package arrives damaged, please report the issue to delivery guy immediately. We will initiate an investigation with the shipping carrier and work to provide a replacement or refund.
            <br/>
            Lost Packages: If your package is marked as delivered but you have not received it, contact our support team. We will assist in tracking the shipment and resolving the issue.
            </div>
            <div className="privacy_title">7. Changes to This Shipping Policy
            </div>
            <div className="privacy_main_content container">
            We may update this Shipping Policy from time to time. The updated version will be posted on our website, and the "Last Updated" at the top of this policy will indicate when the changes take effect. Your continued use of our platform after any changes to the Shipping Policy constitutes your acceptance of the revised policy.
            </div>
            <div className="privacy_title">8. Contact Us
            </div>
            <div className="privacy_main_content container">
            If you have any questions or concerns regarding shipping, please reach out to us at:
            </div>
            <div className="privacy_main_content container">
            Email: contact@svzler.com
            <br/>
Phone: +91 66666-66666
<br/>
Address: B1, Valak Junction, Kamrej, Surat
<br/>
<br/>
            </div>
        </main>
    );
};
