// src/components/Footer.js
import React from 'react';
import './footer.scss'; // Import styles (we'll create this file next)
import { Link } from 'react-router-dom';
export const Footer = () => {
    return (
        <footer>
            <div className="footer_wrapper">
                <div className="container">
                    <div className="footer">
                        <div className="footer_logo">
                            <img src="/img/svzler.png" alt="Svzler logo"/>
                        </div>
                        <div className="footer_links">
                            <Link to="/about">About</Link>
                            <Link to="/privacy">Privacy</Link>
                            <Link to="/terms">Terms</Link>
                            {/* <Link to="/about">FAQs</Link> */}
                            <Link to="/shipping">Shipping</Link>
                            <Link to="/about">Contact</Link>
                        </div>
                        <div className="footer_contact">
                            <div className="footer_Address">
                                <span></span><p>B1, Valak Junction, Kamrej, Surat</p>
                            </div>
                            <div className="footer_phone">
                                <span></span><p>+91 66666-66666</p>
                            </div>
                            <div className="footer_email">
                                <span></span><p>Contact@svzler.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom_footer">
                <p>© 2024 svzler</p>
            </div>
        </footer>
    );
};

