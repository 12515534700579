// redux/slices/cartSlice.js
import { createSlice } from '@reduxjs/toolkit';

// Initial state for the cart
const initialState = {
  products: JSON.parse(localStorage.getItem('cart'))?.products || [],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    // Add or update product in the cart
    addOrUpdateProduct: (state, action) => {
      const { productId, quantity, data } = action.payload;
      const existingProduct = state.products.find(item => item.product === productId);

      if (existingProduct) {
        existingProduct.quantity = quantity;
      } else {
        state.products.push({ product: productId, quantity, data });
      }
      // Update localStorage as well
      localStorage.setItem('cart', JSON.stringify({ products: state.products }));
    },
    
    // Remove product from the cart
    removeProduct: (state, action) => {
      const productId = action.payload;
      state.products = state.products.filter(item => item.product !== productId);

      // Update localStorage
      localStorage.setItem('cart', JSON.stringify({ products: state.products }));
    },
    clearCart: (state) => {
      state.products = []; // Clear cart
    },
  },
});

export const { addOrUpdateProduct, removeProduct, clearCart } = cartSlice.actions;

export default cartSlice.reducer;
