import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'; // Leaflet CSS

// Default marker icon fix (optional)
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const LocationMarker = ({ position, onLocationSelect }) => {
  useMapEvents({
    click(e) {
      const newCoords = e.latlng;
      onLocationSelect(newCoords); // Send coordinates to parent component
    },
  });

  return position === null ? null : (
    <Marker position={position}></Marker>
  );
};

const MapComponent = ({ selectedCoordinates, onLocationSelect }) => {
  const [position, setPosition] = useState(
    selectedCoordinates || { lat: 22.170240, lng: 22.831062 } // Default coordinates
  );

  const mapRef = useRef(); // Create a ref for the map instance

  useEffect(() => {
    if (selectedCoordinates) {
      setPosition(selectedCoordinates);
    }
  }, [selectedCoordinates]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setView(position); // Center the map on the new position
    }
  }, [position]);

  return (
    <MapContainer
      center={position}
      zoom={13}
      style={{ height: '300px', width: '100%' }}
      whenCreated={(mapInstance) => {
        mapRef.current = mapInstance; // Save the map instance to the ref
        mapInstance.setView(position); // Set initial view
      }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <LocationMarker position={position} onLocationSelect={onLocationSelect} />
    </MapContainer>
  );
};

export default MapComponent;
