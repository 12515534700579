import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './header.scss';

export const Header = ({ toggleCart }) => {
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedTerm, setDebouncedTerm] = useState('');
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart.products);

  const calculateTotalCount = (cartData) => {
    return cartData.reduce((acc, item) => acc + parseInt(item.quantity), 0);
  };

  const cartCount = calculateTotalCount(cart);

  // Debounce input to delay navigation until user stops typing
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
      
    }, 300); // 300ms debounce time for smoother experience

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Auto-navigate when debounced search term changes
  // useEffect(() => {
  //   console.log(location.pathname);
  //   if (debouncedTerm.trim() && location.pathname == "/search") {
  //     navigate(`/search?q=${debouncedTerm.trim()}`);
  //   }
  // }, [debouncedTerm, navigate]);

  const handleSearch = () => {
    console.log(location.pathname);
    if (debouncedTerm.trim()) {
      navigate(`/search?q=${debouncedTerm.trim()}`);
    }
  }

  // Preserve search term when returning from the search page
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get('q');
    if (query) {
      setSearchTerm(query); // Set search term if returning from the search page
      
    }
  }, [location.search]);
  useEffect(() => {
    handleSearch();
  }, [debouncedTerm]);

  return (
    <header className="header">
      <div className="container">
        <div className="header_wrapper">
          <div className="header_logo">
            <Link to="/">
              <img src="/img/svzler.png" alt="Svzler logo" />
            </Link>
            <span className="header_b2b">&nbsp;B2B</span>
          </div>

          <div className="header_searchbar">
            <input
              type="text"
              name="search"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              aria-label="Search"
            />
          </div>

          <nav className="header_buttons">
            <button className="cart_toggler" aria-label="View Cart" onClick={toggleCart}>
              <span className="cart-count">{cartCount}</span>
              <img src="/img/cart2.png" alt="Cart" />
            </button>
            <Link to={localStorage.getItem('token') ? "/account" : "/login"}>
              <button className="profile_toggler" aria-label="Profile">
                <img src="/img/user.png" alt="User Profile" />
              </button>
            </Link>
          </nav>
        </div>
        <div className="header_mobile_searchbar">
          <input
            type="text"
            name="search"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            aria-label="Search"
          />
        </div>
      </div>
    </header>
  );
};
