import axios from 'axios';

const API_URL = 'https://api.svzler.com/'; // Replace with your backend URL
// const API_URL = 'http://localhost:5000/'; // Replace with your backend URL


// auth



export const login = async (phoneNumber, password) => {
  try {
    const response = await axios.post(`${API_URL}api/auth/login`, {
      phoneNumber,
      password,
    });
    console.log(response);
    return response.data; // Return the response data from the API
  } catch (error) {
    console.error('Error logging in', error);
    throw new Error('Invalid login credentials');
  }
};

export const getUser = async () => {
  try {
    const token = localStorage.getItem('token'); // Retrieve the token from localStorage
    if (!token) throw new Error('No token found'); 
    const response = await axios.get(`${API_URL}api/auth/get-user`, {
      headers: {
        Authorization: `${token}`, // Send the token in the Authorization header
      },
    });
    console.log(response);
    return response;
  } catch (error) {
    throw new Error('Error fetching categories');
  }
};
export const editUser = async (userData) => {
  try {
    const token = localStorage.getItem('token'); // Retrieve the token from localStorage
    if (!token) throw new Error('No token found');
    
    const response = await axios.put(`${API_URL}api/auth/edit-user`, userData, {
      headers: {
        Authorization: `${token}`, // Send the token in the Authorization header
        'Content-Type': 'application/json', // Ensure the content type is set for JSON
      },
    });

    console.log(response);
    return response;
  } catch (error) {
    console.error('Error updating user:', error);
    throw new Error('Error updating user');
  }
};



// ----------------------

export const getSubCategories = async () => {
  try {
    const response = await axios.get(`${API_URL}api/subcategories/get-subcategory`);
    console.log(response)                                                   
    return response;
  } catch (error) {
    throw new Error('Error fetching categories');
  }
};
export const getProductsbysubcate = async (id) => {
  try {
    const response = await axios.get(`${API_URL}api/products/get-product/subcategory/${id}`);
    console.log(response)                                                   
    return response;
  } catch (error) {
    throw new Error('Error fetching categories');
  }
};


export const placeOrder = async (orderData, token) => {
  try {
    const response = await axios.post(`${API_URL}api/order/place-order`, orderData, {
      headers: {
        Authorization: `${token}`,
      },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Order placement failed");
  }
};
export const getOrders = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}api/order/user-orders`, {
      headers: {
        Authorization: `${token}`,
      },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Order placement failed");
  }
};
export const getSeach = async (query) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}api/products/get-product/search?query=${query}`, {
      headers: {
        Authorization: `${token}`,
      },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Order placement failed");
  }
};
