import React from "react";

export const Privacy = () => {
    return (
        <main>
            <div className="privacy_main_content container p_date">
            Last Updated: 07/09/2024<br/><br/>
            </div>
            <div className="privacy_main_content container">
            svzler ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal information when you interact with our website, mobile application, and services (collectively, the “Platform”). By accessing or using the Platform, you agree to this Privacy Policy. If you do not agree with this Privacy Policy, please do not use our services.
            </div>
            <div className="privacy_title">1. Information We Collect</div>
            <div className="privacy_main_content container">
            We collect the following types of information to provide and improve our services:
            </div>
            <div className="privacy_title">1.1. Personal Information</div>
            <div className="privacy_main_content container">
            We may collect personally identifiable information ("Personal Information") such as:
            <br/>
            Name
            <br/>
            Shop name
            <br/>
            Email address
            <br/>
            Phone number
            <br/>
            Mailing address
            <br/>
            Payment information
            <br/>
            Location data (including GPS coordinates)
            <br/>
            </div>
            <div className="privacy_title">1.2. Non-Personal Information</div>
            <div className="privacy_main_content container">
            We may also collect non-personal information that cannot identify you directly, such as:
            <br/>
            Browser type

            <br/>
            Operating system
            <br/>
            IP address
            <br/>
            Device ID
            <br/>
            Referring website
            </div>
            <div className="privacy_title">1.3. Transaction Information
            </div>
            <div className="privacy_main_content container">
            Details about orders you place, payments you make, and transactions processed through the Platform, including product details, delivery addresses, and payment methods.
            </div>
            <div className="privacy_title">2. How We Use Your Information
            </div>
            <div className="privacy_main_content container">
            We use the information collected for the following purposes:
            <br/>
            To provide, operate, and maintain our services.
            <br/>
            To process your transactions and fulfill orders.

            <br/>
            To improve and personalize your experience on the Platform.
            <br/>
            To communicate with you regarding your account, transactions, or customer service inquiries.

            <br/>
            To send you updates, promotional materials, and other information related to our services (with your consent).

            <br/>
            To comply with legal obligations and protect our rights.
            </div>
            <div className="privacy_title">3. Sharing Your Information
            </div>
            <div className="privacy_main_content container">
            We may share your information in the following circumstances:
            </div>
            <div className="privacy_title">3.1. With Service Providers
            </div>
            <div className="privacy_main_content container">
            We may share your information with trusted third-party service providers who assist us in operating our business, including payment processors, delivery partners, and customer support services. These providers are only permitted to use your information as necessary to perform services on our behalf.
            </div>
            <div className="privacy_title">3.2. For Legal Reasons

            </div>
            <div className="privacy_main_content container">
            We may disclose your information if required by law, such as in response to a court order, legal process, or government request, or to protect our rights, property, and the safety of our users or others.


            </div>
            <div className="privacy_title">3.3. Business Transfers
            </div>
            <div className="privacy_main_content container">
            In the event of a merger, acquisition, or asset sale, your information may be transferred as part of that transaction, subject to this Privacy Policy.
            </div>
            <div className="privacy_title">4. Cookies and Tracking Technologies
            </div>
            <div className="privacy_main_content container">
            We use cookies and similar tracking technologies to collect information about your interaction with our Platform. Cookies help us analyze traffic, improve functionality, and offer personalized content. You may manage or block cookies via your browser settings, though doing so may limit your use of certain features.
            </div>
            <div className="privacy_title">5. Data Security
            </div>
            <div className="privacy_main_content container">
            We take the security of your Personal Information seriously and employ industry-standard practices to protect it. However, no system can guarantee absolute security, and you should take precautions to protect your account and personal information.
            </div>
            <div className="privacy_title">6. Your Data Rights
            </div>
            <div className="privacy_main_content container">
            You may have certain rights regarding your Personal Information, including:
            <br/>
            Access and Correction: You can access and update your Personal Information through your account settings.
            <br/>
            Deletion: You may request the deletion of your Personal Information, but please note that certain information may be required to fulfill legal obligations or to complete any ongoing transactions.
            <br/>
            Opt-Out of Communications: You can opt out of promotional communications by following the unsubscribe instructions in our emails or contacting us directly.
            </div>
            <div className="privacy_title">7. Data Retention
            </div>
            <div className="privacy_main_content container">
            We retain your information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.
            </div>
            <div className="privacy_title">8. Children's Privacy
            </div>
            <div className="privacy_main_content container">
            Our services are not intended for use by individuals under the age of 13. We do not knowingly collect Personal Information from children under 13. If we discover that we have inadvertently collected such information, we will delete it promptly.
            </div>
            <div className="privacy_title">9. Third-Party Links
            </div>
            <div className="privacy_main_content container">
            Our Platform may contain links to third-party websites. We are not responsible for the privacy practices or content of such websites. Please review their privacy policies before submitting any information to them.
            </div>
            <div className="privacy_title">10. Changes to This Privacy Policy
            </div>
            <div className="privacy_main_content container">
            We may update this Privacy Policy from time to time. The "Last Updated" at the top of this page indicates when this policy was last revised. Any changes will become effective when we post the revised policy on our Platform.
            </div>
            <div className="privacy_title">11. Contact Us
            </div>
            <div className="privacy_main_content container">
            If you have any questions or concerns about this Privacy Policy, please contact us at:
            <br/>
            Email: svzler@gmail.com
            <br/>
            Phone: +91 66666-66666
            <br/>
            Address:  B1, Valak Junction, Kamrej, Surat
            <br/>
            <br/>
            </div>
        </main>
    );
};
