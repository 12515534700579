import React from 'react';
import { Link } from 'react-router-dom';
import './categorybox.scss';

export const Categorybox = ({title, img}) => {
    return (
            <div className="categorybox">
                <div className="categorybox_image"><img src={img}/></div>                
                <div className="categorybox_title">{title}</div>                
            </div>
    );
};
