import React from 'react';
import { Link } from 'react-router-dom';
import {ProductBox} from "../parts/productBox/productBox";
import './products.scss';

export const Productslist = ({data }) => {
    return (
        <div className="Products_wrapper">
            <div className="container">
                {/* <div className="Products_title">
                    <h2>New Arrivals</h2>
                </div> */}
                <div className="Products_list">
                        {data?.map((product, index) => (
                            <ProductBox key={index} product={product}/>
                        ))}
                    {/* <div className="newarrival_box">
                        <div className="newarrival_box_image"><img src="/img/c_pen.png"/></div>  
                        <div className="newarrival_box_detail">
                            <div className="newarrival_box_title">Cello pen</div>                
                            <div className="newarrival_box_subtitle">4 packes * 5 pices</div>                
                            <div className="newarrival_box_price">₹450</div>                
                            <div className="newarrival_box_editcart"><button className="minius">-</button>&nbsp;&nbsp;1&nbsp;&nbsp;<button className="plus">+</button></div>                
                        </div>              
                    </div> */}
                </div>
            </div>
        </div>
    );
};
