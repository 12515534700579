import React, { useEffect, useState } from 'react';
// import { Header } from './../components/header/header';
// import { Topbar } from './../components/topbar/topbar';
import { useMutation } from '@tanstack/react-query'; // Import useMutation from React Query
import { login } from './../services/api'; // Import the login function from api.js
import { useNavigate } from 'react-router-dom';
import { LoadingOverlay } from './../components/loadingoverlay/loadingoverlay'; // Import the loading component

export const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  // Define the mutation using useMutation
  const mutation = useMutation({
    mutationFn: async () => {
      const response = await login(phoneNumber, password); // Call login with phoneNumber and password
      console.log(response);
      return response; // Make sure to return the response data
    },
    onSuccess: (data) => {
      localStorage.setItem('token', data.token); // Set token in localStorage
      navigate('/'); // Redirect user to dashboard after successful login
    },
    onError: (error) => {
      setError('Invalid phone number or password');
    },
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    mutation.mutate();
  };


  return (
    <>
      {/* <Topbar />
      <Header /> */}
      <main>
        <div className="login-container">
          <div className="container">
            <h2>Login</h2>
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleLogin}>
              <div className="form-group">
                <input
                  type="text"
                  value={phoneNumber}
                  placeholder="Phone Number"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit" disabled={mutation.isLoading}>
                {mutation.isPending ? 'Logging in...' : 'Login'}
              </button>
            </form>
          </div>
        </div>
      </main>

      {/* Show the LoadingOverlay component when the API is loading */}
      {mutation.isPending && <LoadingOverlay />}
    </>
  );
};
