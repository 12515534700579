import React, { useState } from 'react';
import { Header } from './../components/header/header';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getSeach } from './../services/api'; // Assume this API call is available
import { Topbar } from './../components/topbar/topbar';
import { CartSidebar } from './../components/cartsidebar/cartsidebar';
import { Productslist } from '../components/productslist/productslist';

export const Search = () => {
  const location = useLocation();

  // Extract query from the URL
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('q');

  // Fetch search results using react-query
  const { data: searchResults, isLoading, isError, error } = useQuery({
    queryKey: ['search', query],
    queryFn: () => getSeach(query),
    enabled: !!query, // Only fetch if query exists
  });

  const [isCartOpen, setIsCartOpen] = useState(false);

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  return (
    <div>
      {/* <Topbar />
      <Header toggleCart={toggleCart} isCartOpen={isCartOpen} />
      <CartSidebar isOpen={isCartOpen} toggleCart={toggleCart} /> */}
      <div className="search-page">
        {/* {isLoading ? (
          <div>Loading...</div>
        ) : isError ? (
          <div>Error: {error.message}</div>
        ) : (
          <div> */}
            <Productslist data={searchResults}/>
          {/* </div>
        )} */}
      </div>
    </div>
  );
};