import React from "react";

export const About = () => {
    return (
        <main>
            <div className="aboutus_title">B2B same day delivery solution.</div>
            <div className="aboutus_main_content container">
                At svzler, we are revolutionizing the way businesses and customers
                interact by offering the fastest and most reliable delivery solutions.
                Our mission is to bridge the gap between wholesalers, retailers,
                creating a seamless experience that benefits everyone involved.
            </div>
            <div className="aboutus_title">Who We Are</div>
            <div className="aboutus_main_content container">
                We are a team of passionate innovators committed to streamlining B2B
                deliveries. With a focus on speed, efficiency, and precision, we have
                built a platform that connects wholesalers with retailers and ensures
                fast, reliable delivery to retailers.
            </div>
            <div className="aboutus_title">What We Do</div>
            <div className="aboutus_main_content container">
                We offer a comprehensive platform that:
                <br />
                <br />
                For Retailers: Allows you to browse products from trusted wholesalers,
                place orders with ease, ensuring that you always have stock when you
                need it.
                    <br />
                    <br />
                For Wholesalers: Empowers you to manage your inventory, process orders,
                and grow your business by reaching a larger network of retailers.
            </div>
            <div className="aboutus_title">Our Mission</div>
            <div className="aboutus_main_content container">
            Simplify the ordering and delivery process for all stakeholders.

                <br />
                <br />
                Create opportunities for wholesalers and retailers to grow and thrive.
                <br />
                <br />
                Offer a user-friendly and innovative platform that delivers exceptional customer experiences.

            </div>
            <div className="aboutus_title">Why Choose Us?</div>
            <div className="aboutus_main_content container">
            Fastest Deliveries: We pride ourselves on providing the quickest delivery services in the industry. Retailers can rest assured that their stock will arrive on time, and customers will always be satisfied with prompt deliveries.

                <br />
                <br />
                Reliability: With automated systems and dedicated support, we ensure that orders are processed, managed, and delivered without delays or errors.
                <br/>
                <br/>
                Transparency: From order placement to delivery completion.
            </div>
            <div className="aboutus_title">Join Us
            </div>
            <div className="aboutus_main_content container">
            Whether you're a retailer looking for reliable suppliers, a wholesaler seeking to expand your market, svzler is the platform for you. Together, we’re building the future of business.
            </div>
            <br/>
        </main>
    );
};
