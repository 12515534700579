import React, { useState, useEffect } from 'react';
// import { Header } from './../components/header/header';
// import { Topbar } from './../components/topbar/topbar';
import { useQuery, useMutation } from '@tanstack/react-query'; 
import { getUser, editUser, getOrders } from './../services/api'; // Import the getUser, editUser, and getOrders functions
import { useNavigate } from 'react-router-dom';
// import { CartSidebar } from './../components/cartsidebar/cartsidebar';
import { LoadingOverlay } from './../components/loadingoverlay/loadingoverlay';
import MapComponent from './../components/map/map';

export const Account = () => {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [formData, setFormData] = useState({
    ownerName: '',
    shopName: '',
    email: '',
    address: '',
    mapCoordinates: { lat: '', lng: '' },
    pincode: '',
    city: '',
  });

  const [isLoading, setIsLoading] = useState(false); 
  const navigate = useNavigate();
  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const { data: userData, isError, error } = useQuery({
    queryKey: ['getUser'],
    queryFn: getUser,
    onSuccess: (response) => {
      const user = response.data;
      setFormData({
        ownerName: user.ownerName,
        shopName: user.shopName,
        email: user.email,
        address: user.address,
        mapCoordinates: user.mapCoordinates || { lat: '', lng: '' },
        pincode: user.pincode,
        city: user.city,
      });
    },
    onError: (error) => {
      console.error('Error fetching user data:', error);
    },
  });

  const { data: orders, isLoading: ordersLoading } = useQuery({
    queryKey: ['getOrders'],
    queryFn: getOrders,
    onError: (error) => {
      console.error('Error fetching orders:', error);
    },
  });

  useEffect(() => {
    if (userData) {
      const user = userData?.data;
      setFormData({
        ownerName: user?.ownerName,
        shopName: user?.shopName,
        email: user?.email,
        address: user?.address,
        mapCoordinates: user?.mapCoordinates || { lat: '', lng: '' },
        pincode: user?.pincode,
        city: user?.city,
      });
    }
  }, [userData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleMapSelect = (coordinates) => {
    setFormData((prevData) => ({
      ...prevData,
      mapCoordinates: coordinates,
    }));
  };

  const updateMutation = useMutation({
    mutationFn: editUser,
    onSuccess: () => {
      // navigate('/'); 
    },
    onError: (error) => {
      console.error('Error updating user:', error);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    updateMutation.mutate(formData, {
      onSettled: () => setIsLoading(false),
    });
  };

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      {/* <Topbar />
      <Header toggleCart={toggleCart} isCartOpen={isCartOpen} />
      <CartSidebar isOpen={isCartOpen} toggleCart={toggleCart} /> */}
      <main>
        {isLoading && <LoadingOverlay />}
        <div className="login-container">
          <div className="container">
            <h2>My Account</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="ownerName"
                  value={formData.ownerName}
                  onChange={handleInputChange}
                  placeholder="Owner Name"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="shopName"
                  value={formData.shopName}
                  onChange={handleInputChange}
                  placeholder="Shop Name"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Email"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  placeholder="Address"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleInputChange}
                  placeholder="Pincode"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  placeholder="City"
                  required
                />
              </div>

              <div className="form-group">
                {formData?.mapCoordinates?.lat && 
                  <MapComponent
                    selectedCoordinates={formData?.mapCoordinates}
                    onLocationSelect={handleMapSelect}
                  />
                }
              </div>

              <button type="submit" disabled={isLoading || updateMutation.isLoading}>
                {isLoading || updateMutation.isLoading ? 'Updating...' : 'Update'}
              </button>
            </form>
          </div>
        </div>
        <div className="orders_section">
          <div className="container">
            <h3>My Orders</h3>
            {ordersLoading ? (
              <LoadingOverlay />
            ) : orders?.length > 0 ? (
              <div className="order_list">
                {orders.map((order) => (
                  <div className="order_box" key={order._id}>
                    <div className="order_id_date">
                    <h4>Order ID #{order._id}</h4>
                    <p>Order Date: {new Date(order.createdAt).toLocaleDateString()}</p>
                    </div>
                    <div className="order_amount_status">
                    <div className="order_amount">

                    <p>Shipping Charge: ₹{order.shippingCharge}</p>
                    <p>Total Amount: ₹{order.totalPrice}</p>
                    </div>
                    <div className="order_status">
                    <p>Delivery Status: {order.isDelivered ? 'Delivered' : 'Pending'}</p>
                    <p>Payment Status: {order.isPaid ? 'Paid' : 'Not Paid'}</p>
                    </div>
                    </div>
                    <hr/>
                    <div className="product_list">

                    <h5>Products</h5>
                    <ul>
                      {order.products.map((item) => (
                        <li key={item.product._id}>
                          {item.product.name} - {item.quantity} x ₹{item.product.price}
                        </li>
                      ))}
                    </ul>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>No orders found.</p>
            )}
          </div>
        </div>
      </main>
    </>
  );
};
