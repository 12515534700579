import React from 'react';
import { Link } from 'react-router-dom';
import './topbar.scss';

export const Topbar = () => {
    return (
        <div className="topbar_wrapper">
            <p>Order before 12:30pm so we can deliver same day at 1pm to 2pm, min order 2000rs required&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        </div>
    );
};
