import React from "react";

export const Terms = () => {
    return (
        <main>
            <div className="privacy_main_content container p_date">
            Last Updated: 07/09/2024<br/><br/>
            </div>
            <div className="privacy_main_content container">
            Welcome to svzler. These terms and conditions ("Terms") govern your access to and use of our website, mobile application, and services (collectively, the "Platform"). By accessing or using the Platform, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you must not use our services.
            </div>
            <div className="privacy_title">1. Acceptance of Terms
            </div>
            <div className="privacy_main_content container">
            By using our Platform, you confirm that you are at least 18 years old or that you have the consent of a parent or legal guardian to use our services. Your access to and use of the Platform is conditioned upon your acceptance of and compliance with these Terms.
            </div>
            <div className="privacy_title">2. Account Registration
            </div>
            <div className="privacy_title">2.1. Eligibility</div>
            <div className="privacy_main_content container">
            To use certain features of our Platform, you must register for an account. By registering, you agree to:
            <br/>
            Provide accurate, current, and complete information.


            <br/>
            Maintain and promptly update your account information.

            <br/>
            Keep your password secure and confidential.

            <br/>
            Be responsible for any activity or transactions made through your account.
            </div>
            <div className="privacy_title">2.2. Account Termination

            </div>
            <div className="privacy_main_content container">
            We reserve the right to terminate or suspend your account at any time, with or without notice, for any violation of these Terms or for other reasons at our discretion.
            </div>
            <div className="privacy_title">3. Use of the Platform

            </div>
            <div className="privacy_title">3.1. Permitted Use

            </div>
            <div className="privacy_main_content container">
            You agree to use the Platform only for lawful purposes and in accordance with these Terms. You agree not to:
            <br/>Use the Platform for any fraudulent or illegal activity.

            <br/>
            Violate or infringe upon the rights of others.

            <br/>
            Interfere with or disrupt the functionality or security of the Platform.

            <br/>
            Attempt to gain unauthorized access to the Platform, user accounts, or systems.
            </div>
            <div className="privacy_title">3.2. Prohibited Use

            </div>
            <div className="privacy_main_content container">
            You are prohibited from using the Platform for any purpose that may harm us, our users, or third parties. This includes, but is not limited to:
            <br/>
            Using automated systems to collect data from the Platform without our consent.
            <br/>
            Engaging in any activity that could disable, overburden, or impair the Platform’s functionality.
            <br/>
            Uploading or transmitting harmful or malicious code.
            </div>
            <div className="privacy_title">4. Orders and Transactions

            </div>
            <div className="privacy_title">4.1. Retailer and Wholesaler Orders
            </div>
            <div className="privacy_main_content container">
            When placing an order through our Platform, you agree that:
            <br/>
            All information you provide is accurate and complete.
            <br/>
            You have the legal right to use the payment methods provided.
            <br/>
            Once the order is placed, it cannot be modified or canceled unless allowed by our Refund and Cancellation Policy.
            </div>
            <div className="privacy_title">4.2. Delivery Services
            </div>
            <div className="privacy_main_content container">
            Delivery of products ordered through the Platform is handled by third-party delivery partners. We strive to ensure timely deliveries, but we do not guarantee specific delivery times. Any issues with delivery should be reported to us promptly.

</div>
            <div className="privacy_title">5. Payments and Fees

            </div>
            <div className="privacy_title">5.1. Payment Processing

            </div>
            <div className="privacy_main_content container">
            Payments for orders are processed through third-party payment processors. By using the Platform, you agree to be bound by the terms of these third-party providers. We are not responsible for errors or issues arising from payment processing services.
            </div>
            <div className="privacy_title">5.2. Fees and Charges

            </div>
            <div className="privacy_main_content container">
            All prices, fees, and charges are displayed on the Platform and may vary based on location, taxes, or other factors. By placing an order, you agree to pay the total amount, including any applicable taxes and fees.

</div>
            <div className="privacy_title">6. Refund and Cancellation Policy
            </div>
            <div className="privacy_main_content container">
            We strive to provide high-quality services and products. However, in the event of a cancellation or refund request, please refer to our Refund and Cancellation Policy. Refunds may be processed based on specific conditions outlined in that policy.
            </div>
            <div className="privacy_title">7. Intellectual Property
            </div>
            <div className="privacy_main_content container">
            All content, designs, graphics, logos, and other materials on the Platform are owned or licensed by svzler and are protected by intellectual property laws. You may not copy, reproduce, distribute, or create derivative works from our content without prior written consent from us.</div>
            <div className="privacy_title">8. Limitation of Liability
            </div>
            <div className="privacy_main_content container">
            To the fullest extent permitted by law, svzler and its affiliates, partners, and employees shall not be liable for any indirect, incidental, special, or consequential damages arising out of your use of or inability to use the Platform. This includes, but is not limited to:
            <br/>Loss of profits or data.
            <br/>Damage to reputation or goodwill.

            <br/>Any unauthorized access to or use of our secure servers.

            <br/>In no event shall our total liability exceed the amount you have paid to us for services rendered through the Platform.
            </div>
            <div className="privacy_title">9. Disclaimer of Warranties

            </div>
            <div className="privacy_main_content container">
            The Platform is provided on an "as is" and "as available" basis without any warranties of any kind, express or implied. We do not warrant that:

<br/>
The Platform will be uninterrupted, secure, or error-free.
            <br/>
            Any defects or errors will be corrected.
            <br/>
            The Platform is free of viruses or other harmful components.
            </div>
            <div className="privacy_title">10. Indemnification


            </div>
            <div className="privacy_main_content container">
            You agree to indemnify and hold svzler, its officers, directors, employees, and partners harmless from any claims, liabilities, damages, losses, or expenses (including legal fees) arising from:


<br/>
Your use of the Platform.
            <br/>
            Your violation of these Terms.
            <br/>
            Any misuse of the services or content provided on the Platform.
            </div>
            <div className="privacy_title">11. Termination



            </div>
            <div className="privacy_main_content container">
            We reserve the right to terminate or suspend your access to the Platform at our discretion, without notice, for any reason, including but not limited to:



<br/>
Breach of these Terms.
            <br/>
            Engaging in any fraudulent or illegal activity. Upon termination, you must cease all use of the Platform, and we may delete your account and related information.
            </div>
            <div className="privacy_title">12. Governing Law




            </div>
            <div className="privacy_main_content container">
            These Terms shall be governed by and construed in accordance with the laws of India.
            </div>
            <div className="privacy_title">13. Changes to These Terms





            </div>
            <div className="privacy_main_content container">
            We may modify these Terms from time to time. The "Last Updated" at the top of this page indicates when the Terms were last updated. Any changes will become effective when we post the revised Terms on our Platform. Your continued use of the Platform constitutes your acceptance of the revised Terms.

            </div>
            <div className="privacy_title">14. Contact Us
            </div>
            <div className="privacy_main_content container">
            If you have any questions or concerns about this Privacy Policy, please contact us at:
            <br/>
            Email: svzler@gmail.com
            <br/>
            Phone: +91 66666-66666
            <br/>
            Address:  B1, Valak Junction, Kamrej, Surat
            <br/>
            <br/>
            </div>
        </main>
    );
};
