// productBox.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addOrUpdateProduct, removeProduct } from './../../../redux/cartSlice'; // Adjust path accordingly
// import { addOrUpdateProduct, removeProduct } from '../redux/slices/cartSlice';
import './productBox.scss';

export const ProductBox = ({ product }) => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.products);
  const cartProduct = cart.find((item) => item.product === product._id);

  const handleAddOrUpdate = (productId, quantity, data) => {
    dispatch(addOrUpdateProduct({ productId, quantity, data }));
  };

  const handleRemove = (productId) => {
    dispatch(removeProduct(productId));
  };

  return (
    <div className="productBox_box">
      <div className="productBox_box_image">
        <img src={`https://api.svzler.com/uploads/product_images/${product?.images[0]}`} alt={product?.name} />
      </div>
      <div className="productBox_box_detail">
        <div className="productBox_box_title">{product?.name}</div>
        <div className="productBox_box_subtitle">
          {product?.packs} packs * {product?.pieces} pieces
        </div>
        <div className="productBox_box_price">₹{product?.price}</div>

        {cartProduct ? (
          <div className="productBox_box_editcart">
            <button
              className="minus"
              onClick={() => {
                const newQuantity = parseInt(cartProduct.quantity) - 1;
                if (newQuantity > 0) {
                  handleAddOrUpdate(product._id, newQuantity, product);
                } else {
                  handleRemove(product._id);
                }
              }}
            >
              -
            </button>
            &nbsp;&nbsp;{cartProduct.quantity}&nbsp;&nbsp;
            <button
              className="plus"
              onClick={() =>
                handleAddOrUpdate(product._id, parseInt(cartProduct.quantity) + 1, product)
              }
            >
              +
            </button>
          </div>
        ) : (
          <div className="productBox_box_addcart">
            <button onClick={() => handleAddOrUpdate(product._id, 1, product)}>
              Add to cart
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
