import React, { useState, useEffect, useRef } from 'react';
import './cartsidebar.scss';
import { useSelector, useDispatch } from 'react-redux';
import { addOrUpdateProduct, removeProduct, clearCart } from '../../redux/cartSlice';
import { placeOrder } from '../../services/api';
import { Link } from 'react-router-dom';
import {LoadingOverlay} from '../loadingoverlay/loadingoverlay';

  export const CartSidebar = ({ isOpen, toggleCart }) => {
  const cart = useSelector((state) => state.cart.products);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showSummary, setShowSummary] = useState(false);

  const contentRef = useRef(null); // Reference for dynamic max-height

  const handleAddOrUpdate = (productId, quantity) => {
    dispatch(addOrUpdateProduct({ productId, quantity }));
  };

  const handleRemove = (productId) => {
    dispatch(removeProduct(productId));
  };

  const handleOrder = async () => {
    if (!localStorage.getItem('token')) {
      return;
    }

    const token = localStorage.getItem('token');
    const orderData = {
      products: cart.map((item) => ({
        product: item.product,
        quantity: item.quantity,
      })),
    };

    try {
      setLoading(true);
      await placeOrder(orderData, token);
      setLoading(false);
      dispatch(clearCart());
      toggleCart();
      alert('Order placed successfully!');
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  };

  const calculateCosts = () => {
    const totalProductAmount = cart.reduce((sum, item) => sum + item.data.price * item.quantity, 0);
    const platformFee = 0;
    const handlingCost = 0;
    const deliveryCost = 40;
    const totalAmount = totalProductAmount + platformFee + handlingCost + deliveryCost;
    return { totalProductAmount, platformFee, handlingCost, deliveryCost, totalAmount };
  };

  const { totalProductAmount, platformFee, handlingCost, deliveryCost, totalAmount } = calculateCosts();

  useEffect(() => {
    console.log(cart);
  }, [cart]);

  return (
    <div className={`cart-sidebar ${isOpen ? 'open' : ''}`}>
      <div className='close_cart'>
        <button onClick={toggleCart} className="close-btn">✖</button>
      </div>

      {loading && <LoadingOverlay />}
      {error && <p className="error">{error}</p>}

      {cart.length === 0 ? (
        <p>Your cart is empty</p>
      ) : (
        <>
          <ul>
            {cart.map((item) => (
              <div className='cart_item' key={item.product}>
                <div className="cart_img">
                  <img src={`https://api.svzler.com/uploads/product_images/${item?.data?.images[0]}`} alt={item?.name} />
                </div>
                <div className="cart_detail">
                  <h3>{item?.data.name}</h3>
                  <p className="productBox_box_subtitle">
                    {item?.data.packs} packs * {item?.data.pieces} pieces
                  </p>
                </div>
                <div className='cart_button_price'>
                  <h4>₹{item?.quantity * item?.data.price}</h4>
              
                  <div className='cart_edit_button'>
                    <button
                      className="minus"
                      onClick={() => {
                        const newQuantity = parseInt(item.quantity) - 1;
                        if (newQuantity > 0) {
                          handleAddOrUpdate(item.product, newQuantity);
                        } else {
                          handleRemove(item.product);
                        }
                        }}
                    >
                      -
                    </button>
                    <span>&nbsp;{item.quantity}&nbsp;</span>
                    <button
                      className="plus"
                      onClick={() =>
                        handleAddOrUpdate(item.product, parseInt(item.quantity) + 1)
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </ul>

          {/* Order Summary Accordion */}
          <div className="order-summary">
          <div className="summary-details-wrap">

          <div
              ref={contentRef}
              className="summary-details"
              style={{
                maxHeight: showSummary ? `${contentRef?.current?.scrollHeight}px` : '0',
                opacity: showSummary ? `1` : '0',
                overflow: 'hidden',
                transition: 'max-height 0.3s ease, opacity 0.3s ease', 
              }}
            >
                <p>Total Product Amount: <span>₹{totalProductAmount}</span></p>
                <p>Platform Fee: <span>₹{platformFee} <sup><b>free</b></sup></span></p>
                <p>Handling Cost: <span>₹{handlingCost} <sup><b>free</b></sup></span></p>
                <p>Delivery Cost: <span>₹{deliveryCost}</span></p>
              {/* <p><b>Grand Total: </b><span><b>₹{totalAmount}</b></span></p> */}
            </div>
            </div>

            <button
              className="summary-toggle"
              onClick={() => setShowSummary(!showSummary)}
            >
              Total Amount: <span>₹{totalAmount} {showSummary ? '▼' : '▲'}</span>
            </button>
          </div>

            

          <div className='cart_order_wrapper'>
            {!localStorage.getItem("token") ? (
              <Link to="/login">
                <button className='cart_order'>Login</button>
              </Link>
            ) : (
              <button className='cart_order' onClick={handleOrder}>
                Order Now
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};
